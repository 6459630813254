<template>
    <div>
      <div v-if="showSuccessApprove" class="modal">
            <div class="modal-wrapper">
                <div class="modal-header">
                    <div class="title">{{lang.get("TRANS_CONFIRM")}} </div>
                    <button @click="$emit('close')" data-cy='close-showSuccessApprove'> <i class="i-close"></i></button>
                </div>
                <div class="modal-content">
                    <div class="modal-status">
                        <div class="status-icon">
                            <i class="i-check-full"></i>
                        </div>
                        <div  class="h4">{{lang.get("STAKE_APPROVE_SUCCESS")}}&nbsp;{{stakeAmount}}&nbsp;{{tokenTicker(tokenName)}}</div>
                    </div>
                </div>
            </div>
      </div>
      <div v-if="showSuccessStake" class="modal">
            <div class="modal-wrapper">
                <div class="modal-header">
                    <div class="title">{{lang.get("TRANS_CONFIRM")}} </div>
                    <button @click="$emit('close')" data-cy='close-showSuccessStake'> <i class="i-close"></i></button>
                </div>
                <div class="modal-content">
                    <div class="modal-status">
                        <div class="status-icon">
                            <i class="i-check-full"></i>
                        </div>
                        <div  class="h4">You have successfully staked {{stakeAmount}}&nbsp;{{tokenTicker(tokenName)}}</div>
                        <!-- <button @click="$router.push({name: 'stake'})" class="button button_stake" data-cy='btn-my-stake'>my stake</button> -->
                        <p>Redirecting you to the Stake page...</p>

                    </div>
                </div>
            </div>
      </div>
      <div v-if="showSuccessUnstake" class="modal">
            <div class="modal-wrapper">
                <div class="modal-header">
                    <div class="title">{{lang.get("TRANS_CONFIRM")}} </div>
                    <button @click="$emit('close')" data-cy='close-showSuccessUnstake'> <i class="i-close"></i></button>
                </div>
                <div class="modal-content">
                    <div class="modal-status">
                        <div class="status-icon">
                            <i class="i-check-full"></i>
                        </div>
                        <div  class="h4">You have successfully unstaked {{stakeAmount}}&nbsp;{{tokenTicker(tokenName)}}</div>
                        <button @click="$router.push({name: 'reward'})" class="button button_stake" data-cy='btn-my-stake'>My reward</button>

                    </div>
                </div>
            </div>
      </div>
      

      
      <div v-if="showRejection" class="modal">
            <div class="modal-wrapper">
                <div class="modal-header">
                    <div class="title">{{lang.get("TRANS_FAILED")}} </div>
                    <button @click="$emit('close')" data-cy='close-showRejection'> <i class="i-close"></i></button>
                </div>
                <div class="modal-content">
                    <div class="modal-status">
                        <div class="status-icon">
                            <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M43.9994 87.0125C20.0664 87.0125 0.666016 67.6122 0.666016 43.6792C0.666016 19.7462 20.0664 0.345867 43.9994 0.345867C67.9324 0.345867 87.3327 19.7462 87.3327 43.6792C87.3327 67.6122 67.9324 87.0125 43.9994 87.0125ZM39.666 56.6792V65.3459H48.3327V56.6792H39.666ZM48.3327 49.5509C51.8153 48.5012 54.805 46.2357 56.7574 43.1668C58.7098 40.0978 59.4949 36.4298 58.9699 32.8306C58.4448 29.2313 56.6445 25.9404 53.8967 23.5572C51.149 21.1739 47.6366 19.8569 43.9994 19.8459C40.493 19.8456 37.0949 21.0599 34.3828 23.2822C31.6707 25.5045 29.8121 28.5976 29.123 32.0355L37.625 33.7385C37.8663 32.5314 38.4454 31.4176 39.2949 30.5267C40.1445 29.6359 41.2295 29.0046 42.4239 28.7063C43.6182 28.408 44.8727 28.4549 46.0413 28.8417C47.21 29.2285 48.2448 29.9392 49.0253 30.8911C49.8058 31.8431 50.3 32.9971 50.4503 34.2189C50.6006 35.4407 50.4009 36.68 49.8744 37.7927C49.3478 38.9054 48.5162 39.8458 47.4761 40.5043C46.436 41.1628 45.2304 41.5125 43.9994 41.5125C42.8501 41.5125 41.7479 41.9691 40.9352 42.7817C40.1226 43.5944 39.666 44.6966 39.666 45.8459V52.3459H48.3327V49.5509Z" fill="white"/>
                            </svg>
                        </div>
                        <div  class="h4">{{lang.get("TRANS_FAILED_MESSAGE")}}<br/></div>
                    <div class="container_button">
                        <button  class="button button_canel" data-cy='btn-showRejection-1'>cancel</button>
                        <button  class="button button_continue" data-cy='btn-showRejection-2'>cancel</button>
                    </div>
                    </div>
                </div>
            </div>
      </div>

      <div v-if="showError" class="modal">
            <div class="modal-wrapper">
                <div class="modal-header">
                    <div class="title">{{lang.get("TRANS_STATUS")}}</div>
                    <button @click="$emit('close')" data-cy='close-showError'> <i class="i-close"></i></button>
                </div>
                <div class="modal-content">
                    <div class="modal-status">
                        <div class="status-icon">
                            <i class="i-close"></i>
                        </div>
                        <div  class="h4">{{lang.get("TRANS_FAILED_MESSAGE")}}<br/></div>
                    </div>
                </div>
            </div>
      </div>
      <div v-if="showEthNotEnough" class="modal">
            <div class="modal-wrapper">
                <div class="modal-header">
                    <div class="title">{{lang.get("TRANS_STATUS")}}</div>
                    <button @click="$emit('close')" data-cy='close-showError'> <i class="i-close"></i></button>
                </div>
                <div class="modal-content">
                    <div class="modal-status">
                        <div class="status-icon">
                            <i class="i-close"></i>
                        </div>
                        <div  class="h4">{{lang.get("NOT_ENOUGTH_ETH")}}<br/></div>
                    </div>
                </div>
            </div>
      </div>
        

      <div v-if="showPendingApprove" class="modal">
            <div class="modal-wrapper">
                <div class="modal-header">
                    <div class="title">{{lang.get("TRANS_PROCESSING")}} </div>
                    <button @click="$emit('close')" data-cy='close-showPendingApprove'> <i class="i-close"></i></button>
                </div>
                <div class="modal-content">
                    <div class="modal-status">
                        <div class="status-icon">
                            <i class="i-load"></i>
                        </div>
                        <div  class="h4">{{lang.get("YOU_APPROVING")}} {{stakeAmount}}&nbsp;{{tokenTicker(tokenName)}}<br/>{{lang.get("WAIT_FOR_BBLOCK")}}</div>
                    </div>
                </div>
            </div>
        </div>
      <div v-if="showPendingStake" class="modal">
            <div class="modal-wrapper">
                <div class="modal-header">
                    <div class="title">{{lang.get("TRANS_PROCESSING")}} </div>
                    <button @click="$emit('close')" data-cy='close-showPendingStake'> <i class="i-close"></i></button>
                </div>
                <div class="modal-content">
                    <div class="modal-status">
                        <div class="status-icon">
                            <i class="i-load"></i>
                        </div>
                        <div  class="h4">{{lang.get("YOU_STAKING")}} {{stakeAmount}}&nbsp;{{tokenTicker(tokenName)}}<br/>{{lang.get("WAIT_FOR_BLOCK")}}</div>
                    </div>
                </div>
            </div>
      </div>
      <!-- <div v-if="showPendingStake" class="modal">
            <div class="modal-wrapper">
                <div class="modal-header">
                    <div class="title">Transaction processing </div>
                    <button @click="$emit('close')" data-cy='close-showPendingStake-2'> <i class="i-close"></i></button>
                </div>
                <div class="modal-content">
                    <div class="modal-status">
                        <div class="status-icon">
                            <i class="i-load"></i>
                        </div>
                        <div  class="h4">Staking in pending {{stakeAmount}}&nbsp;{{tokenName}}.<br/>Waiting for blockchain confirmations</div>
                    </div>
                </div>
            </div>
      </div> -->
      <div v-if="showPendingUnstake" class="modal">
            <div class="modal-wrapper">
                <div class="modal-header">
                    <div class="title">{{lang.get("TRANS_PROCESSING")}} </div>
                    <button @click="$emit('close')" data-cy='close-showPendingUnstake'> <i class="i-close"></i></button>
                </div>
                <div class="modal-content">
                    <div class="modal-status">
                        <div class="status-icon">
                            <i class="i-load"></i>
                        </div>
                        <div  class="h4">{{lang.get("YOU_UNSTAKING")}} {{stakeAmount}}&nbsp;{{tokenTicker(tokenName)}}<br/>{{lang.get("WAIT_FOR_BLOCK")}}</div>
                    </div>
                </div>
            </div>
      </div>
    </div>
</template>

<script>
import MultiLang from '@/core/multilang'; 
import Config from '../../../Config.json'
export default {
  props:['showPendingApprove', 'showPendingStake', 'showPendingUnstake',  'showSuccessApprove', 'showSuccessStake', 'showSuccessUnstake', 'stakeAmount', 'tokenName', 'showRejection', 'showError', 'showEthNotEnough'],
  data: function() {
    return {
      lang: new MultiLang(this),
    }
  },
  mounted() {
    this.lang.init()
  },
  methods: {
      tokenTicker(tokenName) {
        return Config.tokenAliases[tokenName];
    }
  }
}
</script>